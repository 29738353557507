<template>
  <v-container
      id="regular-tables"
      fluid
      tag="section"
  >
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <ValidationObserver v-slot="{invalid, reset}" ref="form">
        <v-form @reset.prevent="reset">
          <v-card>
            <v-card-title>
              <span class="headline" v-if="selectedItem">Edit shipping package</span>
              <span class="headline" v-else>Create shipping package</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <ValidationProvider
                        name="title"
                        rules="required"
                        v-slot="{errors}"
                    >
                      <v-text-field
                          label="Title"
                          v-model="form.title"
                          required
                          :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider
                        name="width"
                        rules="required|numeric"
                        v-slot="{errors}"
                    >
                      <v-text-field
                          label="Width"
                          v-model="form.width"
                          requeired
                          :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                        name="height"
                        rules="required|numeric"
                        v-slot="{errors}"
                    >
                      <v-text-field
                          label="Height"
                          v-model="form.height"
                          requeired
                          :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                        name="depth"
                        rules="required|numeric"
                        v-slot="{errors}"
                    >
                      <v-text-field
                          label="Depth"
                          v-model="form.depth"
                          required
                          :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                        name="weight"
                        rules="required|decimal"
                        v-slot="{errors}"
                    >
                      <v-text-field
                          label="Weight"
                          v-model="form.weight"
                          required
                          :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                        label="Is document"
                        v-model="form.is_document"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
              <small>All dimension in CM/KG</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="onDialogClose"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :disabled="invalid"
                  @click="onDialogSave"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
    <v-card
        icon="mdi-clipboard-text"
        title="Shipping packages"
        class="px-5 py-3"
    >
      <v-toolbar flat>
        <v-spacer/>
        <v-btn class="primary" @click="onAddClick">Add shipping package</v-btn>
      </v-toolbar>

      <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :loading="loading"
          @page-count="pageCount = $event"
          @click:row="handleClick"
      >
        <template v-slot:item.is_document="{item}">
          <span class="text-center">
            <v-icon
                small
                color="green"
                v-if="item.is_document"
            >
              mdi-check-bold
            </v-icon>
          </span>
        </template>
      </v-data-table>

      <v-pagination
          v-if="pageCount > 1"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import ShippingPackage from "@/models/ShippingPackage";

export default {
  data() {
    return {
      dialog: false,
      loading: true,
      selectedItem: null,
      overlay: false,
      page: 1,
      pageCount: 10,
      itemsPerPage: 10,
      form: {
        title: '',
        width: '',
        height: '',
        depth: '',
        weight: '',
        is_document: false
      },
      headers: [
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Width',
          value: 'width'
        },
        {
          text: 'Height',
          value: 'height'
        },
        {
          text: 'Depth',
          value: 'depth'
        },
        {
          text: 'Is document',
          value: 'is_document',
          align: 'center'
        },
      ],
    }
  },
  computed: {
    items() {
      return ShippingPackage.all();
    }
  },
  methods: {
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    onAddClick() {
      this.selectedItem = null;
      this.dialog = true;
    },
    clearDialog() {
      this.selectedItem = null;
      this.form = {
        title: '',
        width: '',
        height: '',
        depth: '',
        weight: '',
        is_document: false
      }
      this.dialog = false;
      this.resetForm();
    },
    onDialogClose() {
      this.clearDialog()
    },
    async onDialogSave() {
      this.dialog = false;
      this.overlay = true;
      if (this.selectedItem) {
        await ShippingPackage.api().put(`/api/v1/shipping/shipping_packages/${this.selectedItem}/`, this.form)
      } else {
        await ShippingPackage.api().post('/api/v1/shipping/shipping_packages/', this.form)
      }
      this.selectedItem = false
      this.clearDialog();
      this.resetForm()
      this.overlay = false;
    },
    handleClick(item) {
      this.selectedItem = item.id
      this.form.title = item.title
      this.form.depth = parseInt(item.depth)
      this.form.height = parseInt(item.height)
      this.form.width = parseInt(item.width)
      this.form.weight = item.weight
      this.form.is_document = item.is_document
      this.dialog = true
      this.resetForm()
    }
  },
  async mounted() {
    await ShippingPackage.api().get('/api/v1/shipping/shipping_packages/')
    this.loading = false;
    this.resetForm()
  }
}
</script>