var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('v-form',{on:{"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[(_vm.selectedItem)?_c('span',{staticClass:"headline"},[_vm._v("Edit shipping package")]):_c('span',{staticClass:"headline"},[_vm._v("Create shipping package")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Title","required":"","error-messages":errors},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"width","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Width","requeired":"","error-messages":errors},model:{value:(_vm.form.width),callback:function ($$v) {_vm.$set(_vm.form, "width", $$v)},expression:"form.width"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"height","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Height","requeired":"","error-messages":errors},model:{value:(_vm.form.height),callback:function ($$v) {_vm.$set(_vm.form, "height", $$v)},expression:"form.height"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"depth","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Depth","required":"","error-messages":errors},model:{value:(_vm.form.depth),callback:function ($$v) {_vm.$set(_vm.form, "depth", $$v)},expression:"form.depth"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"weight","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Weight","required":"","error-messages":errors},model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", $$v)},expression:"form.weight"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Is document"},model:{value:(_vm.form.is_document),callback:function ($$v) {_vm.$set(_vm.form, "is_document", $$v)},expression:"form.is_document"}})],1)],1)],1),_c('small',[_vm._v("All dimension in CM/KG")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onDialogClose}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid},on:{"click":_vm.onDialogSave}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1),_c('v-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"Shipping packages"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"primary",on:{"click":_vm.onAddClick}},[_vm._v("Add shipping package")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.is_document",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-center"},[(item.is_document)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-check-bold ")]):_vm._e()],1)]}}])}),(_vm.pageCount > 1)?_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }